import React, { useState } from "react"
import { Link } from "gatsby"
import { Box, Button, Flex, Heading, Text, useTheme } from "@chakra-ui/core"

// import Logo from "../images/logo.svg" // TODO: Setup svg-loader
import { SocialLinks } from "../components/socialLink"

const NavLink = ({ children, to, ...rest }) => (
  <Box mt={{ base: 4, md: 0 }} mr={6} display="block">
    <Link to={to} {...rest}>
      {children}
    </Link>
  </Box>
)

const Header = props => {
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <Flex width="100%" mx="auto">
      <Flex
        as="nav"
        align="center"
        boxShadow="md"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        bg="white"
        color="pink.500"
        pos="fixed"
        top="0"
        width="100%"
        zIndex="1000"
        {...props}
      >
        <Flex align="center" mr={5}>
          <Heading as="h1" size="lg" letterSpacing={"-.1rem"}>
            <Link to="/" fontWeight="bold">
              <svg
                width="32"
                height="32"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="30" cy="30" r="30" fill="#EF2A73" />
                <path
                  d="M31.371 29.7286C32.1514 29.4868 33.067 29.0485 34.1176 28.4138C35.1983 29.7286 34.75 31.2701 33.8925 32.25C33.2361 33 32.5 33.25 30.8757 33.7184C30.5155 36.4689 30.3354 38.5394 30.3354 39.9298C30.3354 41.3201 30.5755 42.0153 31.0558 42.0153C31.8062 42.0153 32.7518 41.3352 33.8925 39.9751C35.0332 38.6149 36.1138 36.741 37.1344 34.3531C38.155 31.9653 38.7854 29.5624 39.0255 27.1443C39.0255 24.1822 37.7498 21.4166 35.1983 18.8474C35.8586 17.5477 36.7142 16.3689 37.7648 15.311C38.8454 14.2531 39.746 13.5126 40.4664 13.0894L41.547 12.5C42.8979 12.5 44.0535 13.2859 45.0141 14.8576C46.0047 16.3991 46.5 18.2882 46.5 20.5249C46.5 22.7314 45.8696 25.4819 44.6089 28.7765C43.3481 32.0711 41.7872 35.1239 39.9261 37.9349C38.065 40.7459 35.9637 43.1337 33.6223 45.0983C31.2809 47.0328 29.0746 48 27.0034 48C24.9321 48 23.4913 47.3955 22.6808 46.1865C21.9003 44.9774 21.5101 43.2999 21.5101 41.1539C21.5101 40.096 21.6752 37.7384 22.0054 34.0811C19.2437 33.93 17.0524 32.993 15.4315 31.2701C13.8105 29.517 13 27.5373 13 25.3308C13 22.4896 14.2758 20.1017 16.8273 18.1673C19.3788 16.2329 22.0954 15.2656 24.9772 15.2656C27.0184 15.2656 28.6994 15.9306 30.0202 17.2605C31.341 18.5602 32.0013 20.5854 32.0013 23.3359C32.0013 24.4542 31.7912 26.5851 31.371 29.7286ZM23.041 23.5626C23.041 20.8423 22.3356 19.4821 20.9247 19.4821C19.9642 19.4821 19.0036 20.0715 18.043 21.2503C17.1125 22.4291 16.6472 23.7893 16.6472 25.3308C16.6472 26.8723 17.1875 28.1115 18.2681 29.0485C19.3788 29.9553 20.7596 30.4087 22.4106 30.4087C22.8309 27.0839 23.041 24.8018 23.041 23.5626Z"
                  fill="#FFE0EA"
                />
              </svg>
              {/* Onnistua.fi */}
            </Link>
          </Heading>
          <Box ml="4">
            <Link to="/" activeClassName="current-page">
              Onnistua.fi
            </Link>
          </Box>
        </Flex>

        <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
          <svg
            fill="#EF2A73"
            width="16px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </Box>

        <Flex
          display={{ base: show ? "block" : "none", md: "flex" }}
          width={{ base: "full", md: "auto" }}
          alignItems="center"
          justifyContent="flex-end"
          flexGrow={1}
        >
          <NavLink to="/ajanvaraus" activeClassName="current-page">
            Ajanvaraus
          </NavLink>
          <NavLink to="/esittely" activeClassName="current-page">
            Esittely
          </NavLink>
          <NavLink to="/opiskelijat" activeClassName="current-page">
            Opiskelijat
          </NavLink>
          <NavLink to="/ota-yhteytta" activeClassName="current-page">
            Ota yhteyttä
          </NavLink>
          <SocialLinks mr={{ md: 3 }} mt={{ base: 2, md: 0 }} />
          <Button
            as={Link}
            mt={{ base: 2, md: 0 }}
            role="link"
            to="/ajanvaraus"
            variant="outline"
            variantColor="pink"
          >
            Varaa aika
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
