import React from "react"
import Helmet from "react-helmet"
import useSiteMetadata from "../hooks/use-sitemetadata"
import { Box } from "@chakra-ui/core"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata()

  return (
    <>
      <Helmet>
        <html lang="fi" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header />
      <Box as="main" mt="70px" bg="white">
        {children}
      </Box>
      <Footer></Footer>
    </>
  )
}

export default Layout
