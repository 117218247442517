import React from "react"
import { Box, Link, PseudoBox, VisuallyHidden } from "@chakra-ui/core"
import { FaFacebook, FaInstagram } from "react-icons/fa"

export const SocialLink = ({ href, icon, label, size = "32px", ...props }) => (
  <PseudoBox {...props}>
    <Link href={href}>
      <Box as={icon} size={size} />
      <VisuallyHidden>{label}</VisuallyHidden>
    </Link>
  </PseudoBox>
)

export const SocialLinks = props => (
  <>
    <SocialLink
      href="https://www.instagram.com/vilmaslashandbeauty/"
      icon={FaInstagram}
      label="Onnistua.fi Instagram"
      {...props}
    />
    <SocialLink
      href="https://www.facebook.com/Onnistua.fi/"
      icon={FaFacebook}
      label="Onnistua.fi Facebook"
      {...props}
    />
  </>
)

export default { SocialLink, SocialLinks }
