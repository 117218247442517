import React from "react"
import { Link } from "gatsby"
import {
  Box,
  Button,
  Heading,
  PseudoBox,
  SimpleGrid,
  Text,
  useTheme,
} from "@chakra-ui/core"

// import GoogleMap from "../components/googleMap"
import { SocialLinks } from "../components/socialLink"

const FooterSection = props => (
  <Box
    as="footer"
    pos="relative"
    bg="white"
    py={{ base: "32px", lg: "40px" }}
    {...props}
  />
)

const FooterSectionGroup = props => {
  const theme = useTheme()
  return (
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      maxWidth={theme.breakpoints.lg}
      mx="auto"
      {...props}
    />
  )
}

const FooterSectionItem = props => <PseudoBox p="4" color="grey" {...props} />

const Footer = () => {
  const theme = useTheme()
  return (
    <>
      <Box w="100%" bg="pink.100" textAlign="center" p="6">
        <Box maxW={theme.breakpoints.lg} mx="auto">
          <Heading color="pink.800" mb="4">
            Ajanvaraus
          </Heading>
          <Text color="pink.800" fontSize="lg" mb="6">
            Ajanvaraus on helpointa hoitaa netissä, mutta voit myös soittaa
            meille tai laittaa sähköpostia.
          </Text>
          <Button
            as={Link}
            mt={{ base: 2, md: 0 }}
            role="link"
            to="/ajanvaraus"
            variantColor="pink"
          >
            Varaa aika
          </Button>
        </Box>
      </Box>
      <FooterSection alignSelf="flex-end">
        <FooterSectionGroup>
          <FooterSectionItem>
            <div itemScope itemType="http://schema.org/LocalBusiness">
              <Heading as="h4" fontSize="xl">
                <span itemProp="name">
                  Onnistua.fi & Vilma's lash and beauty
                </span>
              </Heading>
              <span itemProp="description">Vastuullinen kauneushoitola</span>
              <div
                itemProp="address"
                itemScope
                itemType="http://schema.org/PostalAddress"
              >
                <span itemProp="streetAddress">Viitaniementie 24 A</span>
                <span itemProp="postalCode">40720</span>{" "}
                <span itemProp="addressLocality">Jyväskylä</span>
              </div>
              <p>
                Soita: <span itemProp="telephone">041 318 1305</span>
              </p>
              <p>
                Kirjoita:{" "}
                <a href="mailto:info@onnistua.fi" itemProp="email">
                  info@onnistua.fi
                </a>
              </p>
            </div>
          </FooterSectionItem>

          <FooterSectionItem>
            <Heading as="h4" fontSize="xl">
              Aukioloajat
            </Heading>
            <data itemProp="openingHours" value="Tu,We,Th 11:30-19:00">
              Tiistaista torstaihin klo 11:30-19:00
            </data>
            <Heading as="h4" fontSize="xl">
              Seuraa meitä somessa
            </Heading>
            <Box display={{ sm: "flex", md: "flex" }} mt={{ base: 4, md: 0 }}>
              <SocialLinks mt="2" mr="2" />
            </Box>
            <Heading as="h4" fontSize="xl">
              Miten tulla meille?
            </Heading>
            <p>
              Pääset hoitolan lähelle busseilla numero 5 ja 16. Viitaniementien
              varressa on ilmaisia parkkipaikkoja joiden välistä lähtee
              vasemmalla kaartuva pelastustie. Hoitola sijaitsee tien päässä
              olevan talon kellarikerroksessa.
            </p>
          </FooterSectionItem>

          <FooterSectionItem>
            <Heading as="h4" fontSize="xl">
              Sijaintimme kartalla
            </Heading>
            <Box
              allowFullScreen
              as="iframe"
              frameBorder="0"
              height="400px"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJrb4tkzh0hUYRGKgBmBSk3Nw&key=AIzaSyARXMRonmiLoUQgZbiiY8vgCkPCZcthvz8"
              style={{ border: 0 }}
              title="Sijainti Google Mapsissä"
              width="100%"
            ></Box>
            {/* <GoogleMap /> */}
          </FooterSectionItem>
        </FooterSectionGroup>
      </FooterSection>
    </>
  )
}

export default Footer
